@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: rgb(17 24 39);
    --secondary: #083b86;
}

* {
    font-family: "Quicksand", sans-serif;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: var(--primary);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border: 3px solid var(--primary);
}

@layer components {
    .gifs {
        @apply border-[1px] w-screen border-black h-full
    }
}